
    .p-name-banner {
        position: absolute;
        top: 16px;
        left: 48px;
        border-radius: 5px;
        transform: translate3d(0, -50%, 0);
    }

    .p-name-banner-small {
        left: 0;
        width: 100%
    }
