
    .p-head-box {
        overflow: hidden;
        /* position: absolute; */
        border-radius: 18px;
        /* transform: translate3d(0, 50%, 0); */
        box-shadow: 0px 0px 42px -4px var(--q-primary);
        transition: .3s all;
        background: var(--q-accent2)
    }

    .p-head-box:hover {
        overflow: hidden;
        /* position: absolute; */
        border-radius: 18px;
        /* transform: translate3d(0, 50%, 0); */
        box-shadow: 0px 0px 42px -4px var(--q-pagistoni_dark);
        background: var(--q-accent2)
    }
