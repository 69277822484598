
    .p-card {
        transition: .3s all;
    }

    .p-card:hover {
        box-shadow: 0px 0px 42px -4px var(--q-primary);
    }

    .p-team-image {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate3d(-50%, 0, 0)
    }
