
    p {
        margin: 0
    }

    .p-price-announcer {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate3d(50%, -98px, 0)
    }

    .p-price {
        font-size: 5rem;
        font-weight: 600;
        position: relative;
    }

    .p-price-sub-text {
        position: absolute;
        bottom: 0;
        right: 0;
        font-weight: 600;
    }

    .p-price-hint {
        ul {
            list-style: none;
            padding-left: 0;
            li {
                font-size: 0.8rem;
                font-weight: 300;
                padding-left: 0
            }
        }
    }

    .p-price-headline {
        font-size: 1.25rem;
        font-weight: 500;
    }
