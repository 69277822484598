
    .background_img::before {
        background-repeat: no-repeat;
        position: absolute;
        bottom: 15px;
        left: 0;
        height: calc(100% - 115px);
        width: 100%;
        transform: translate3d(calc(-53% * v-bind('position')), 0, 0);
        background-position: 50% 50%;
        content: '';
        transition: all .5s;
        z-index: 5;
        background-image: url('https://cdn2.pagisto.com/graphics/website/graphics/boom_green.svg');
    }

    .background_img::after {
        background-repeat: no-repeat;
        position: absolute;
        bottom: 32px;
        left: 0;
        height: calc(100% - 132px);
        width: 100%;
        transform: translate3d(calc(50% * v-bind('position')), 0, 0);
        background-position: 50% 50%;
        content: '';
        background-size: contain;
        transition: all .5s;
        z-index: 5;
        background-image: url('https://cdn2.pagisto.com/graphics/website/graphics/boom2_green.svg');
    }

    .ground_element {
        background-image: url('https://cdn2.pagisto.com/graphics/website/graphics/ground_green.svg');
        width: 500%;
        height: 200px;
        position: absolute;
        bottom: 0;
        left: 0;
        content: ''
    }
