
    .p_slogan {
        white-space: nowrap;
        overflow: hidden;
        font-family: 'Nanum Gothic Coding', monospace;
    }

    .p_slogan_desktop {
        animation: animated-text 4s steps(29,end) 1s 1 normal both,
                animated-cursor 600ms steps(29,end) infinite;
        border-right: solid 3px var(--q-pagisto);
    }

    /* text animation */
    @keyframes animated-text{
        0% {
        width: 0
        }
        69% {
        width: 97%
        }
        99% {
        width: 97%
        }
        100% {
        width: 99.5%
        }
    }

    /* cursor animations */

    @keyframes animated-cursor{
        from{border-right-color: var(--q-primary)}
        to{border-right-color: transparent;}
    }

    h1 {
        margin-block-start: 0;
        margin-block-end: 0
    }
